<!--
 * @Description: 课程资源管理
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:43:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-25 10:18:26
-->
<template>
  <div class="course-resources">
    <table-list
      title="试题库列表"
      :loading="loading"
      :data="studentList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ singleCurrent: true }"
      @search="onSearch"
      @singleSelectChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <importChapter ref="importChapter" @success="getCourse()"></importChapter>
    <el-dialog
      title="创建题库名称"
      :visible.sync="dialogVisible"
      width="580px"
      :close-on-click-modal="false"
    >
      <el-input v-model="inputName" placeholder="请输入2-18字题库名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCreate">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑题库名称"
      :visible.sync="dialogVisible1"
      width="580px"
      :close-on-click-modal="false"
    >
      <el-input v-model="inputName1" placeholder="请输入2-18字题库名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleCreate1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '创建题库名称',
    type: 'primary',
    method: _this.handleAdd,
  },
  {
    label: '下载导入模板',
    method: _this.handleDown,
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '题库名称',
    prop: 'questionStoreName',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'questionStoreName',
    label: '题库名称',
  },
  {
    prop: 'questionTypeMuster',
    label: '试题包含类型',
  },
  {
    prop: 'questionNum',
    label: '题目数量',
    sortable: 'sortable',
    align: 'right',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
  {
    label: '查看详情',
    method: _this.handleLook,
  },
  {
    label: '导入试题',
    method: _this.handleOn,
  },
  {
    label: '删除',
    method: _this.handleDelete,
  },
]
import TableList from '@/components/TableList'
import importChapter from '../CourseLibrary/components/importChapter'
import { examList, examDelete, examDown, examCreate, examEdit } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseResources',
  components: {
    TableList,
    importChapter,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      dialogVisible: false,
      dialogVisible1: false,
      inputName: '',
      inputName1: '',
      id: '',
      loading: false,
      studentList: [],
      selectStudents: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getCourseResource()
  },
  methods: {
    async getCourseResource() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(examList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getCourseResource()
    },

    async handleDown() {
      const [res, err] = await to(examDown())
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '题库模板.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleOn(row) {
      this.$refs.importChapter.show(2, row.questionStoreId)
      this.$refs['importChapter'].$refs['fileRefs'].$refs['upload-inner'].handleClick()
    },
    handleLook(row) {
      this.$router.push({
        path: 'examDetail',
        query: { questionStoreId: row.questionStoreId, title: row.questionStoreName },
      })
    },

    handleAdd() {
      this.dialogVisible = true
    },
    async handleCreate() {
      if (this.inputName.length < 2 || this.inputName.length > 18)
        return this.$message.warning('请输入2-18字题库名称')
      const [, err] = await to(examCreate({ platformType: 0, questionStoreName: this.inputName }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('创建成功')
      this.dialogVisible = false
      this.inputName = ''
      this.getCourseResource()
    },
    handleEdit(row) {
      this.id = row.questionStoreId
      this.inputName1 = row.questionStoreName
      this.dialogVisible1 = true
    },
    async handleCreate1() {
      if (this.inputName1.length < 2 || this.inputName1.length > 18)
        return this.$message.warning('请输入2-18字题库名称')
      const [, err] = await to(examEdit({ id: this.id, questionStoreName: this.inputName1 }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('编辑成功')
      this.dialogVisible1 = false
      this.inputName1 = ''
      this.getCourseResource()
    },
    // 删除
    async handleDelete(row) {
      let id = [row.questionStoreId]
      if (row.bindCourseName == '') {
        this.$confirm('试题库包含' + row.questionNum + '道试题，' + '您确认删除题库吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const [, err] = await to(examDelete({ questionStoreIds: id }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('删除成功')
          this.getCourseResource()
        })
      }
      if (row.bindCourseName == '' && row.questionNum == 0) {
        this.$confirm('您确认删除题库吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const [, err] = await to(examDelete({ questionStoreIds: id }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('删除成功')
          this.getCourseResource()
        })
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudents = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourseResource()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourseResource()
    },
  },
}
</script>
