<!--
 * @Description: 课程资源管理
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:43:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-25 14:39:09
-->
<template>
  <div class="course-resources">
    <table-list
      title="资源列表"
      :loading="loading"
      :data="studentList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>

    <el-dialog :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <video
        id="playerVideo"
        ref="media"
        :src="playURL"
        controls="controls"
        style="100%;height:100%;object-fit:fill"
        class="playerVideo"
      ></video>
    </el-dialog>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '添加课程资源',
    method: _this.handleAdd,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '资源名称/ID',
    prop: 'keyword',
  },
  {
    label: '时长',
    type: 'select',
    prop: 'time',
    children: [
      { label: '小于30分钟', value: 1 },
      { label: '30分钟~60分钟', value: 2 },
      { label: '60分钟~120分钟', value: 3 },
      { label: '120分钟以上', value: 4 },
    ],
  },
  // {
  //   label: '分组',
  //   type: 'select',
  //   prop: 'deptId',
  // },
  {
    label: '资源类别',
    type: 'select',
    prop: 'materialType',
    children: [
      { label: '视频', value: 1 },
      { label: '音频', value: 2 },
    ],
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'videoId',
    label: '资源ID',
  },
  {
    prop: 'coverImageUrl',
    label: '资源封面',
    render: (h, { row }) => {
      return [
        row.coverImageUrl
          ? h('img', {
              domProps: { src: row.coverImageUrl },
              style: 'height: 50px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'materialType',
    label: '资源类别',
    formatter: row => {
      return ['视频', '音频'][row.materialType - 1]
    },
  },
  {
    prop: 'name',
    label: '资源名称',
  },
  {
    prop: 'durationStr',
    label: '资源时长',
  },
  // {
  //   prop: 'groupName',
  //   label: '分组',
  // },
  {
    prop: 'uploadTime',
    label: '上传时间',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
  {
    label: '预览',
    method: _this.handleLook,
    isShow: item => item.videoPlatform != 3 && item.videoPlatform != 4,
  },
]
import TableList from '@/components/TableList'
import { courseResourses, videoDetail, courseDel } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseResources',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      studentList: [],
      selectStudents: [],
      deptList: [],
      classList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      playURL: '',
      dialogVisible: false,
    }
  },
  mounted() {
    this.getCourseResource()
  },
  methods: {
    async getCourseResource() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(courseResourses({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getCourseResource()
    },
    async handleLook(row) {
      const [res, err] = await to(
        videoDetail({ videoId: row.videoId, videoPlatform: row.videoPlatform }),
      )
      if (err) return this.$message.warning(err.msg)
      this.playURL = res.data.videoUrl
      this.dialogVisible = true
    },
    // 新增
    handleAdd() {
      this.$router.push('/course/resources/add')
    },
    // 编辑
    handleEdit({ id }) {
      this.$router.push(`/course/resources/edit/${id}`)
    },
    // 删除
    async handleDelete() {
      if (!this.selectStudents.length) return this.$message.warning('请先选择要删除的课程资源')
      let resources = []
      this.selectStudents.map(item => {
        resources.push({ id: item.id, materialType: item.materialType })
      })
      this.$confirm('确认删除资源吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(courseDel({ resources }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getCourseResource()
      })
    },
    handleClose() {
      this.dialogVisible = false
      var playerVideo = document.getElementById('playerVideo')
      playerVideo.currentTime = 0
      playerVideo.pause()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudents = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourseResource()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourseResource()
    },
  },
}
</script>
<style scoped lang="scss">
.course-resources {
  ::v-deep .el-dialog__body {
    height: 500px;
  }
}
.playerVideo {
  width: 100%;
  height: 500px;
  object-fit: fill;
}
</style>
