<!--
 * @Description: 课程资源管理
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:43:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-25 10:18:26
-->
<template>
  <div class="course-resources">
    <table-list
      class="tableList"
      :title="title + '-试题详情'"
      :loading="loading"
      :back-btn="{
        name: '返回题库',
        method: back,
      }"
      :data="studentList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
    <importChapter ref="importChapter" @success="getCourse()"></importChapter>
    <el-dialog title="试题详情" :visible.sync="dialogVisible" width="580px">
      <div>
        <span v-show="optionType == 1" class="icon_box">单选题</span
        ><span v-show="optionType == 2" class="icon_box">多选题</span
        ><span v-show="optionType == 3" class="icon_box">问答题</span>
        {{ optionName }}
      </div>
      <div v-for="item in optionList" :key="item.questionOptionId" class="option_box">
        <span>{{ item.optionTag }}.</span><span>{{ item.optionName }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '导入试题',
    method: _this.handleOn,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '试题名称',
    prop: 'questionName',
  },
  {
    label: '试题类型',
    type: 'select',
    prop: 'questionType',
    children: [
      { value: 1, label: '单选' },
      { value: 2, label: '多选' },
      { value: 3, label: '问答' },
    ],
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'questionName',
    label: '试题名称',
    twoLines: true,
  },
  {
    prop: 'questionType',
    label: '试题类型',
    minWidth: '100px',
    formatter: row => {
      return { 1: '单选', 2: '多选', 3: '问答' }[row.questionType]
    },
  },
  {
    prop: 'importTime',
    label: '导入时间',
    minWidth: '200px',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '查看',
    method: _this.handleLook,
  },
]
import TableList from '@/components/TableList'
import importChapter from '../CourseLibrary/components/importChapter'
import { questionList, questionDelete } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseResources',
  components: {
    TableList,
    importChapter,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      dialogVisible: false,
      studentList: [],
      selectStudents: [],
      optionList: [],
      optionName: '',
      optionType: 0,
      questionStoreId: this.$route.query.questionStoreId,
      title: this.$route.query.title,
      pager: {
        total: 0,
        size: 50,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getCourseResource()
  },
  methods: {
    back() {
      this.$router.push('examList')
    },
    async getCourseResource() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        questionList({ current, size, ...this.queryParams, questionStoreId: this.questionStoreId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getCourseResource()
    },
    handleOn() {
      this.$refs.importChapter.show(2, this.questionStoreId)
      this.$refs['importChapter'].$refs['fileRefs'].$refs['upload-inner'].handleClick()
    },
    //
    handleLook(row) {
      this.dialogVisible = true
      this.optionList = row.optionList
      this.optionName = row.questionName
      this.optionType = row.questionType
    },

    // 删除
    async handleDelete() {
      if (!this.selectStudents.length) return this.$message.warning('请先选择要删除的试题')
      let questionIds = []
      this.selectStudents.map(item => {
        questionIds.push(item.questionId)
      })
      this.$confirm('确认删除试题吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(
          questionDelete({ questionIds: questionIds, questionStoreId: this.questionStoreId }),
        )
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getCourseResource()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudents = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourseResource()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourseResource()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep.tableList .el-table tr .tableRowTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.icon_box {
  display: inline-block;
  padding: 3px 10px;
  background: #8c82f5;
  border-radius: 12px;
  color: #fff;
  margin: 0 4px;
}
.option_box {
  line-height: 40px;
  font-size: 14px;
  padding: 0 18px;
}
</style>
